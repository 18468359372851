import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import Profile from "./components/accounts/Profile";
import Home from "./components/accounts/Home";
import WithPrivateRoute from "./utils/WithPrivateRoute";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import { Navigate } from "react-router-dom";
import Loader from "./components/accounts/Loader"; // Adjust the path to your loader component
import Box from '@mui/material/Box'; // Import Box from MUI

if (process.env.REACT_APP_NODE_ENV === "production") {
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  console.info = function () {};
  console.debug = function () {};
}

function App() {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    const preloadVideo = async () => {
      const video = document.createElement('video');
      video.src = '/back1.mp4'; // Update this to your video path
      video.preload = 'auto';

      video.onloadeddata = () => {
        setVideoUrl(video.src);
      };

      video.onerror = () => {
        setVideoError(true);
      };
    };

    preloadVideo();
  }, []);

  return (
    <AuthProvider>
      <Router>
        <MainContent videoUrl={videoUrl} videoError={videoError} />
      </Router>
    </AuthProvider>
  );
}

function MainContent({ videoUrl, videoError }) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate loading time

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '5px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
                borderRadius: '5px',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: '#888 #f1f1f1',
            }}
          >
            <Routes>
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/home" element={<Home videoUrl={videoUrl} videoError={videoError} />} />
              <Route exact path="/" element={<Home videoUrl={videoUrl} videoError={videoError} />} />
              <Route exact path="*" element={<Home videoUrl={videoUrl} videoError={videoError} />} />
              <Route
                exact
                path="/profile"
                element={
                  <WithPrivateRoute>
                    <Profile />
                  </WithPrivateRoute>
                }
              />
              <Route exact path="*" element={<Navigate to="/home" />} />
            </Routes>
          </Box>
          <Footer />
        </>
      )}
    </Box>
  );
}

export default App;
