import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {'Copyright © '}
      <Link color="text.secondary" href="">
        ExPay Financial Canada
      </Link>
      &nbsp;{new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        // bgcolor: '#009BDF',
        color: '#ffffff',
        textAlign: 'center',
        fontSize: '14px',
        borderTop: '1px solid black', // Add a black top border
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', // Add shadow effec
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, sm: 4 },
          py: { xs: 2, sm: 4 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            <Copyright />
          </div>
          <Stack
            direction="column"
            alignItems="center"
            spacing={0.5}
            sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Email: customerservice@expay.ca

            </Typography>
          
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ justifyContent: 'center', color: 'text.secondary', mt: { xs: 2, sm: 0 } }}
          >
             <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Phone/WhatsApp: +1 (888) 606 9729 
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
